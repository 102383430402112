import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Button, AppBar, Toolbar, Container, Typography, Popover, CircularProgress } from '@mui/material';
import TableRowsRoundedIcon from '@mui/icons-material/TableRowsRounded';
import HomeIcon from '@mui/icons-material/Home';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useOffSetTop from 'src/hooks/useOffSetTop';
import Logo from 'src/assets/images/whiteUpskill_ReskillLogo.png';
//
import ModalComponent from 'src/components/Modal/Modal';
import main_logo from 'src/assets/images/UP_RElogo.png';
import { navConfig } from '../nav';
import HeaderShadow from '../../components/HeaderShadow';

// ----------------------------------------------------------------------

export default function Header({ headerOnDark,clgDetails,isLoading }) {
  const params = useParams();
  const navigate = useNavigate()
  const isOffset = useOffSetTop();
  const [open, setOpen] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const navigateHome = () => {
    if (params?.cg_name) {
      navigate(`${params?.cg_name}`)
    }
  } 
  
  useEffect(()=>{
    if(clgDetails){
      setShowHeader(true);
    }else{
      setShowHeader(false);
    }
  }, [clgDetails])

  const redirectUrl=(data,type)=>{
    let renderLink = ''
    if(data && data.length > 0){
        const httpCheck = data.toLocaleLowerCase().startsWith("http")
        if(httpCheck){
        renderLink = data
        }else{
            renderLink = `http://${data}`
        }
    }
    return(
        // <a href={renderLink} rel="noreferrer" target='_blank'> <Typography variant='medium' color="white" sx={{textTransform:'none',display:'flex',alignItems:'center',fontSize:'1.05rem'}} >{type}<KeyboardArrowRightIcon sx={{color:'white'}} /></Typography></a> 
        renderLink
    )
}

  const handleOpen = () => { 
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if(showHeader){
    return (
      <AppBar color="transparent" className='AppHeader'>
        <Toolbar
          disableGutters
        >
            <Box className="nav-wrapper">
             { window.location.pathname !==`/${params?.cg_name}`  && <Button
                aria-describedby='nav-menu'
                variant="text"
                className='nav-open'
                onClick={navigateHome}
              >
                <Typography variant='button'><HomeIcon/></Typography>
              </Button>}
              <Button
                aria-describedby='nav-menu'
                variant="text"
                className='nav-open'
                onClick={handleOpen}
              >
                <Typography variant='button'><TableRowsRoundedIcon/></Typography>
              </Button>
  
              <ModalComponent
                open={open}
                handleClose={handleClose}
                className='sidebar-nav'
                type="sidebar"
              >
                <Button
                aria-describedby='nav-menu'
                variant="text"
                onClick={handleClose}
                className='close-btn'
                >
                  <Typography variant='button'><CloseRoundedIcon/></Typography>
                </Button>
                {!isLoading ?
                <Box className='sidebar-wrapper'>
                  <a target='_blank' rel="noreferrer" href={redirectUrl(clgDetails.data.website)}>
                  <img className='clg-logo' src={ clgDetails?.data?.logo? clgDetails?.data?.logo : main_logo} alt="College Logo"/>
                  </a>
                  <Box className='clg-info-wrapper'>
                    <Box className='clg-info'>
                      <Typography variant='bold'>Address</Typography>
                      <Typography variant='body1'>
                        {clgDetails?.data?.name ? clgDetails?.data?.name : ''} 
                        <br/> {clgDetails?.data?.address1 ? clgDetails?.data?.address1 : '-'}
                        <br/> {clgDetails?.data?.city ? clgDetails?.data?.city : ''}
                        <br/> {clgDetails?.data?.state ? clgDetails?.data?.state : ''} {clgDetails?.data?.zip ? clgDetails?.data?.zip : ''}
                      </Typography>
                    </Box>
                    <Box className='clg-info'>
                      <Typography variant='bold'>Phone</Typography>
                      {
                        clgDetails?.data?.contactNumber ? 
  
                        <Typography variant='body1' onClick={handleClose}><a href={`tel:${clgDetails?.data?.contactNumber}`}>{clgDetails?.data?.contactNumber}</a></Typography>
                        : <Typography variant='body1'><a>-</a></Typography>
                      }
                    </Box>
                    <Box className='clg-info'>
                      <Typography variant='bold'>Email</Typography>
                      {
                        clgDetails?.data?.email ? 
  
                        <Typography variant='body1' onClick={handleClose}><a href={`mailto:${clgDetails.data.email}`}>{clgDetails.data.email}</a></Typography>
                        : <Typography variant='body1'><a>-</a></Typography>
                      }
                    </Box>
                    <Box className='clg-info'>
                      <Typography variant='bold'>Website</Typography>
                      {
                        clgDetails?.data?.website ? 
  
                        <Typography variant='body1' onClick={handleClose}><a target='_blank' rel="noreferrer" href={redirectUrl(clgDetails.data.website)}>{clgDetails.data.website}</a></Typography>
                        : <Typography variant='body1'><a>-</a></Typography>
                      }
                    </Box>
                  </Box> 
                  <Box className='nav-links'>
                    {/* <Link to={`${params.cg_name}/privacy-policy`} onClick={handleClose}>Privacy Policy</Link> */}
                    <Link to={`${params.cg_name}/terms-conditions`} onClick={handleClose}>Terms & Condition</Link>
                    {/* <Link to={`${params.cg_name}/disclaimer`} onClick={handleClose}>Disclaimer</Link> */}
                  </Box>
                  <Box className='nav-footer'>
                    <Box className='logo'>
                      <Typography variant='medium'>powered by</Typography>
                      <img src={Logo} alt="Logo" />
                    </Box>
                    <Typography>©2023 Horizon AI Limited</Typography>
                  </Box>
                </Box>
                : <CircularProgress />}
              </ModalComponent>
            </Box>
        </Toolbar>
  
        {isOffset && <HeaderShadow />}
      </AppBar>
    );
  }
}

Header.propTypes = {
  headerOnDark: PropTypes.bool,
  clgDetails: PropTypes.string,
  isLoading: PropTypes.bool
};
