import { Navigate, useRoutes } from 'react-router-dom';

import { base_route_url } from 'src/utils';
// layouts
import MainLayout from '../layouts/main';
//
import {
  Page404,
  HomePage,
  UpskillSkilldar,
  UpskillCareerCourses,
  ReskillSkilldar,
  ReskillCareerCourses,
  ReportPage,
  CareerHistory,
  HaveACode,
  CollegeNotFound,
  PrivacyPolicy,
  TearmsAndConditions,
  Disclaimer,
} from './elements';

// ----------------------------------------------------------------------

export default function Router({getCollegeColors}) {
  return useRoutes([
    // Main layout
    {
      element: <MainLayout getCollegeColors={getCollegeColors}/>,
      children: [
        {element: <HomePage/>, path: `${base_route_url}:cg_name`},
        { element: <HaveACode/>, path: `${base_route_url}code` },
        { element: <CareerHistory/>, path: `${base_route_url}:cg_name/career-history` },
        { element: <UpskillSkilldar/>, path: `${base_route_url}:cg_name/upskill/skilldar` },
        { element: <UpskillCareerCourses/>, path: `${base_route_url}:cg_name/upskill/career-courses` },
        { element: <ReskillSkilldar/>, path: `${base_route_url}:cg_name/reskill/skilldar` },
        { element: <ReskillCareerCourses/>, path: `${base_route_url}:cg_name/reskill/career-courses` },
        { element: <ReportPage/>, path: `${base_route_url}report` },
        { element: <PrivacyPolicy/>, path: `${base_route_url}:cg_name/privacy-policy` },
        { element: <TearmsAndConditions/>, path: `${base_route_url}:cg_name/terms-conditions` },
        { element: <Disclaimer/>, path: `${base_route_url}:cg_name/disclaimer` },
        { element: <CollegeNotFound/>, path: '/college-not-found' },
        { element: <Page404/>, path: '*' },
      ],
    },
  ]);
}
