import {
  Box,
  Typography,
} from '@mui/material';
import Logo from 'src/assets/images/UP_RElogo.png';

export default function Footer() {

  return (
    <footer className='AppFooter'>
      <Box className='logo'>
        <Typography variant='medium'>powered by</Typography>
        <img src={Logo} alt="Logo" />
      </Box>
    </footer>
  )
}


