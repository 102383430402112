import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Alert, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function AlertComponent ({alert,handleClose})  {
    useEffect(()=>{
        if(alert.open){
            setTimeout(() => {
                handleClose();
            }, 4000);
        }
    }, [alert.open]) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            {
            alert.open ?
                <Box className='alert-wrapper'>
                    <Alert
                    severity={alert?.type === "success" ? "success" : "error"}
                    action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={handleClose}
                        >
                        <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{fontSize: '16px !important'}}
                    >
                    {alert.msg}
                    </Alert>
                </Box>
            : ''
            }
        </>
    )
  
}
AlertComponent.propTypes = {
    handleClose: PropTypes.func,
    alert: PropTypes.object,
};