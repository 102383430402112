import { createSlice } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Api_Base_URL } from 'src/config-global';

export const CareersApi = createApi({
    reducerPath: 'CareersApi',
    baseQuery: fetchBaseQuery({ baseUrl: Api_Base_URL }),
    endpoints: (builder) => ({
        getCareers: builder.mutation({
            query: data => ({
                url: '/getCareers',
                method: 'POST',
                body: data
            }),
            transformResponse: (response, meta, arg) => response.data,
            transformErrorResponse: (response, meta, arg) => response,
        }),
        getCareerGoals: builder.mutation({
            query: data => ({
                url: '/getGoalCareers',
                method: 'POST',
                body: data
            }),
            transformResponse: (response, meta, arg) => response.data,
            transformErrorResponse: (response, meta, arg) => response,
        }),
    }),
})

const CareerHistorySlice = createSlice({
    name:'CareerHistory',
    initialState:{
        Careers: {},
        CareerHistory :[],
        CareerGoal: null
    },
    reducers:{
        addCareerHistory : (state, action) =>{
            state.CareerHistory = action.payload
        },
        addCareerGoal : (state, action) =>{
            state.CareerGoal = action.payload
        },
    }
})
export const {useGetCareersMutation, useGetCareerGoalsMutation} = CareersApi;
export const {addCareerHistory, addCareerGoal} = CareerHistorySlice.actions;
export default CareerHistorySlice.reducer;