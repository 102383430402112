import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, Outlet, useLocation, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
// @mui
import { Box, CircularProgress } from '@mui/material';
// config
import { HEADER } from 'src/config-global';
//
import AlertComponent from 'src/components/Alert/Alert';
import { analytics, getCurrentPath } from 'src/utils';
import Header from './header/Header';
import Footer from './footer/Footer';
import { addCollegeDetails, addSlug, setAlert, useGetClgBySlugQuery } from './MainLayoutSlice';

// ----------------------------------------------------------------------

const pathsOnDark = ['/career/landing', '/travel/landing'];

const spacingLayout = [...pathsOnDark, '/', '/e-learning/landing', '/marketing/landing'];

// ----------------------------------------------------------------------

MainLayout.propTypes = {
  getCollegeColors: PropTypes.func
};

export default function MainLayout({getCollegeColors}) {
  const { pathname } = useLocation();
  const { cg_name } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [skip, setSkip] = useState(true);
  const [clgName, setClgName] = useState(Cookies.get('url-slug'));
  const { data:clgDetails, error, isLoading} = useGetClgBySlugQuery(cg_name, {skip});
  const alertState = useSelector((state)=>state.mainLayout.alert);
  const collegeDetails = useSelector(state => state.mainLayout?.collegeDetails)
  const currentPage = useSelector(state => state.mainLayout?.currentPage)
  useEffect(()=>{
    if(cg_name !== clgName){
      Cookies.set('url-slug', cg_name);
    }
    if(cg_name){
      setSkip(false);
      dispatch(addSlug(cg_name));
    }
  },[cg_name, clgName]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
   if(clgDetails && currentPage){
    const collegeId =  clgDetails?.data?._id;
    // const currentPage = currentPage
    const pageLeaveObj = {
      collegeId,
      currentPage
    }
    localStorage.setItem('pageLeaveObj', JSON.stringify(pageLeaveObj))
   }
  }, [clgDetails, currentPage])
  
  const alertUser = e => {
    // e.preventDefault()
    // e.returnValue = ''
    // const pathArray = window.location.pathname.split('/')
    // const currentPath = pathArray[pathArray.length - 1]
    // if(collegeDetails?._id){
      const {collegeId:thisCollege, currentPage:thisPage} = JSON.parse(localStorage.getItem('pageLeaveObj'))
      analytics({
        collegeId : thisCollege,
        event: 'PAGE_LEAVE',
        from: thisPage,
      }).then(()=>{
        localStorage.removeItem('userIp')
        localStorage.removeItem('pageLeaveObj')
      })
    // }
  }
  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    // getIp()
    return () => {
      window.removeEventListener('beforeunload', alertUser)
      // window.removeEventListener('unload', alertUser)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=>{
    if(clgDetails?.success){
      getCollegeColors({
        primary: clgDetails.data.primaryColor,
        secondary: clgDetails.data.secondaryColor,
      })
      Cookies.set('college-id', clgDetails.data._id);

      dispatch(addCollegeDetails(clgDetails.data));
      analytics({
        collegeId: clgDetails?.data?._id,
        event: 'LANDING_PAGE',
        from: getCurrentPath(),
      })}
    else if(error?.data?.success === false){
      navigate('/college-not-found');
      dispatch(setAlert({
        open: true,
        msg: error?.data.message ? error?.data.message : "Something went wrong"
      }))
    }
    
  }, [clgDetails, error]); // eslint-disable-line react-hooks/exhaustive-deps

  const actionPage = (arr) => arr.some((path) => pathname === path);
  const onClose = () => {
    dispatch(setAlert({
      open: false,
      msg: ''
    }))
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      
      <Header clgDetails={clgDetails} isLoading={isLoading} headerOnDark={actionPage(pathsOnDark)} />
      <AlertComponent alert={alertState} handleClose={onClose}/>
      {
        isLoading ?
          <Box
          component="main"
          sx={{
            flexGrow: 1,
            minHeight: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress />
        </Box>  
        :
        <Box
          component="main"
          sx={{
            flexGrow: 1,
          }}
        >
          {!actionPage(spacingLayout) && <Spacing />}
          <Outlet />
        </Box>
      }
      <Footer />
    </Box>
  );
}

// ----------------------------------------------------------------------

function Spacing() {
  return (
    <Box
      sx={{
        height: { xs: HEADER.H_MOBILE, md: HEADER.H_MAIN_DESKTOP },
      }}
    />
  );
}
