import { createSlice } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Api_Base_URL } from 'src/config-global';

export const ReskillApi = createApi({
    reducerPath: 'ReskillApi',
    baseQuery: fetchBaseQuery({ baseUrl: Api_Base_URL }),
    endpoints: (builder) => ({
        getCareersReskillTime: builder.mutation({
            query: data => ({
                url: '/getCareersNReskillTime',
                method: 'POST',
                body: data
            }),
            transformResponse: (response, meta, arg) => response.data,
            transformErrorResponse: (response, meta, arg) => response,
        }),
    })
})

const ReskillSlice = createSlice({
    name:'Reskill',
    initialState:{
        CompareCareers: []
    },
    reducers:{
        addCompareCareers : (state, action) =>{
            state.CompareCareers.push(action.payload)
        },
        removeCompareCareers : (state, action) =>{
            state.CompareCareers = state.CompareCareers.filter((career)=> career.id !== action.payload.id) 
        },
    }
})

export const {useGetCareersReskillTimeMutation } = ReskillApi;
export const {addCompareCareers, removeCompareCareers} = ReskillSlice.actions;
export default ReskillSlice.reducer
