import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const HomePage = Loadable(lazy(() => import('../pages/Home/Home')));
export const HaveACode = Loadable(lazy(() => import('../pages/HaveACode/HaveACode')));
export const CareerHistory = Loadable(lazy(()=> import('../pages/CareerHistory/CareerHistory')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const UpskillSkilldar = Loadable(lazy(() => import('../pages/Upskill_Flow/Steps/Skilldar')));
export const UpskillCareerCourses = Loadable(lazy(() => import('../pages/Upskill_Flow/Steps/CareerCourses')));
export const ReskillSkilldar = Loadable(lazy(() => import('../pages/Reskill_Flow/Steps/Skilldar')));
export const ReskillCareerCourses = Loadable(lazy(() => import('../pages/Reskill_Flow/Steps/CareerCourses')));
export const ReportPage = Loadable(lazy(() => import('../pages/Test/ReportPage')));
export const CollegeNotFound = Loadable(lazy(() => import('../pages/CollegeNotFound')));
export const PrivacyPolicy = Loadable(lazy(() => import('../pages/NavbarPages/PrivacyPolicy')));
export const TearmsAndConditions = Loadable(lazy(() => import('../pages/NavbarPages/TearmsAndConditions')));
export const Disclaimer = Loadable(lazy(() => import('../pages/NavbarPages/Disclaimer')));