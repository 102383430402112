export default function palette(themeMode, themeOverride) {
  const light = {
    mode: 'light',
    primary: {
      main: themeOverride && themeOverride.primary ?  themeOverride.primary : '#7040f1',
      dark: '#000c39',
      white: '#fff',
      black: '#1f1f1f',
      light: '#637480',
      error: '#ff3333',
      success: '#4BB543',
      pink: '#F9B5D7'
    },
    secondary: {
      main: themeOverride && themeOverride.secondary ?  themeOverride.secondary :'#FE2097',
      orange: '#FEA33C',
      pink: '#FF4291'
    }
  };

  const dark = {
    mode: 'dark',
    primary: {
      main: '#7040f1',
      dark: '#29106b',
      white: '#fff',
      black: '#1f1f1f',
    },
    secondary: {
      main: '#FE2097',
      orange: '#FEA33C',
      pink: '#FF4291'
    }
  };

  return themeMode === 'light' ? light : dark;
}
