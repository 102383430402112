import { createSlice } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Api_Base_URL } from 'src/config-global';

export const MainLayoutApi = createApi({
    reducerPath: 'MainLayoutApi',
    baseQuery: fetchBaseQuery({ baseUrl: Api_Base_URL }),
    endpoints: (builder) => ({
        getClgBySlug: builder.query({
            query: (slug) => `/getCollegeBySlug?slug=${slug}`,
        }),
        postPdf : builder.mutation({
            query: data => ({
                url: '/sendEmail',
                method: 'POST',
                body: data
            }),
            transformResponse: (response, meta, arg) => response.data,
            transformErrorResponse: (response, meta, arg) => response,
        }),
    }),
})

const MainLayoutSlice = createSlice({
    name:'MainLayout',
    initialState:{
        userIp: '',
        slug: '',
        collegeDetails: {},
        alert: {
            open: false,
            msg: ''
        },
        compareCareers: [],
        currentPage: 'home-page'
    },
    reducers:{
        addIp : (state, action) => {
            state.userIp = action.payload
        },
        addSlug : (state, action) =>{
            state.slug = action.payload
        },
        addCollegeDetails : (state, action) =>{
            state.collegeDetails = action.payload
        },
        setAlert : (state, action) => {
            state.alert = action.payload
        },
        addCompareCareer : (state, action) => {
            if(state.compareCareers.some(stateCareer => stateCareer === action.payload)){
                state.compareCareers = state.compareCareers.filter(career => career !== action.payload)
            }else{
                state.compareCareers.push(action.payload)
            }
        },
        setCurrentPage : (state, action) => {
            if(action.payload){
                state.currentPage = action.payload
            }
        },
        resetCompare : (state) => {
            state.compareCareers = []
        }
        
    }
})
export const {useGetClgBySlugQuery, usePostPdfMutation} = MainLayoutApi;
export const {addIp, addSlug, addCollegeDetails, setAlert, addCompareCareer, resetCompare, setCurrentPage} = MainLayoutSlice.actions;
export default MainLayoutSlice.reducer;