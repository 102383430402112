import { createSlice } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Api_Base_URL } from 'src/config-global';

export const UpskillApi = createApi({

    reducerPath: 'UpskillApi',
    baseQuery: fetchBaseQuery({ baseUrl: Api_Base_URL }),
    endpoints: (builder) => ({
        getSkilldarChartData: builder.mutation({
            query: data => ({
                url: '/getSkilldarChartData',
                method: 'POST',
                body: data
            }),
            transformResponse: (response, meta, arg) => response.data,
            transformErrorResponse: (response, meta, arg) => response,
        }),
        getCareersUpskillTime: builder.mutation({
            query: data => ({
                url: '/getCareersNUpskillTime',
                method: 'POST',
                body: data
            }),
            transformResponse: (response, meta, arg) => response.data,
            transformErrorResponse: (response, meta, arg) => response,
        }),
        careerNCoursesDetails: builder.mutation({
            query: data => ({
                url: '/getCareersNCoursesDetails',
                method: 'POST',
                body: data
            }),
            transformResponse: (response, meta, arg) => response.data,
            transformErrorResponse: (response, meta, arg) => response,
        }),
        getCompareCareersData: builder.mutation({
            query: data => ({
                url: '/getCompareCareersData',
                method: 'POST',
                body: data
            }),
            transformResponse: (response, meta, arg) => response.data,
            transformErrorResponse: (response, meta, arg) => response,
        }),
        getSkillsReportData: builder.mutation({
            query: data => ({
                url: '/getSkillsReportData',
                method: 'POST',
                body: data
            }),
            transformResponse: (response, meta, arg) => response.data,
            transformErrorResponse: (response, meta, arg) => response,
        }),
    }),
})


const UpskillSlice = createSlice({
    name:'Upskill',
    initialState:{
        CompareCareers: [],
        SkillsReport:{}
    },
    reducers:{
        addCompareCareers : (state, action) =>{
            state.CompareCareers.push(action.payload)
        },
        addSkillReport : (state, action) =>{
            // console.log('pay',action.payload);
            state.SkillsReport = action.payload
        },
    }
})

export const {useGetSkilldarChartDataMutation, useGetCareersUpskillTimeMutation,useCareerNCoursesDetailsMutation, useGetCompareCareersDataMutation,useGetSkillsReportDataMutation } = UpskillApi;
export const {addCompareCareers,addSkillReport} = UpskillSlice.actions;
export default UpskillSlice.reducer

