import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import axios from "axios";
import axiosInstance from "./axiosInstance";

export const base_route_url = '/';
export const analytics = async (body) => {
    const userIp = JSON.parse(localStorage.getItem('userIp'))
    const pathArray = window.location.pathname.split('/')
    const currentPath = pathArray[pathArray.length - 1]
    const data = {
        collegeId: body?.collegeId,
        event: body?.event,
        from: body.from ? body.from : currentPath,
        // IP: body?.IP,
        data: body?.data,
    }
    if(!userIp){
        const res = await axios.get("https://api.ipify.org/?format=json");
        if (res?.data?.ip) {
            data.IP = res.data.ip
        }
    }else{
        data.IP = userIp
    }
    try {
        axiosInstance({
            url: "analytics/addLogs",
            method: "Post",
            data
        })
    } catch (error) {
        console.log("error", error)
    }
}

export const  getCurrentPath = () => {
    const pathArray = window.location.pathname.split('/');
    const currentPath = pathArray[pathArray.length - 1]
    if (pathArray.length <= 2) {
        return 'landing'
    }
    return currentPath && currentPath
}

export const exportPDFWithMethod = (postPdf) => {
    const gridElement = document.querySelector(".report-container");
    setTimeout(() => {
        drawDOM(gridElement, {
            paperSize: "A4",
        })
            .then((group) => exportPDF(group))
            .then((dataUri) => {
                const pdfUrl = dataUri.split(";base64,")[1]
                if(postPdf && typeof postPdf === 'function'){
                    postPdf(pdfUrl)
                }
                // console.log('Base 64 version ==> ',dataUri.split(";base64,")[1]);
            });
    }, 400);
  
};

export const currencyFormat = (num) => (
    new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(num)
)