import axios from "axios";
import { Api_Base_URL } from "src/config-global";

const axiosInstance = axios.create({
  // baseURL: "http://localhost:3001/api/",
  baseURL: '/api/',
  headers: {
    //  Authorization: `<Auth Token>`,
    "Content-Type": "application/json",
    // timeout : 1000,
  },
});

export default axiosInstance;