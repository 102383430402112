// API
// ----------------------------------------------------------------------

export const GOOGLE_MAP_API = process.env.REACT_APP_MAP_API;

// LAYOUT
// ----------------------------------------------------------------------

export const NAV = {
  W_BASE: 260,
  W_DRAWER: 280,
  //
  H_ITEM: 48,
  H_ITEM_SUB: 36,
};

export const ICON = {
  NAV_ITEM: 24,
};

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
};

// export const Api_Base_URL = 'http://localhost:3001/api/frontend/';
export const Api_Base_URL = '/api/frontend/';
export const Ai_Video_Url = 'https://absolutebyte.ams3.digitaloceanspaces.com/upskillreskill.co.uk/app/videos/'